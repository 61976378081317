import React from 'react';
import HotelLinkInBio from './components/HotelLinkInBio';

function App() {
  return (
    <div className="min-h-screen bg-white-100 py-8">
      <HotelLinkInBio />
    </div>
  );
}

export default App;